/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap'); */
body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
