.h38 {
  height: 38px;
}
.h68 {
  height: 68px;
}
.h28 {
  height: 28px;
}

.h200px{
  height: 200px;
}

.maxh80vh {
  max-height: 80vh;
}

/* ----------------------------------border radius */
.bordrad4 {
  border-radius: 4px;
}
.bordrad6 {
  border-radius: 6px;
}
/* ----------------------------------------------------------PADDING */
.p1per {
  padding: 1%;
}
.p2per {
  padding: 2%;
}

/* Margindfd--------------- */
.ml10 {
  margin-left: 10px;
}
.ml12 {
  margin-left: 10px;
}

.mt8 {
  margin-top: 8px;
}
.mt12 {
  margin-top: 12px;
}
.mt14 {
  margin-top: 12px;
}
.mt21 {
  margin-top: 21px;
}
.mt22 {
  margin-top: 22px;
}
.mt25 {
  margin-top: 25px;
}
.mt20px {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt32 {
  margin-top: 30px;
}
.mt34 {
  margin-top: 34px;
}
.mt70 {
  margin-top: 70px;
}
.mt60 {
  margin-top: 60px;
}

.mt50 {
  margin-top: 50px;
}
.mt150 {
  margin-top: 150px;
}
/* /* Margin right */
.mr10 {
  margin-right: 10px;
}
.mr12 {
  margin-right: 12px;
}
.mr14 {
  margin-right: 14px;
}
.mr16 {
  margin-right: 16px;
}
.ml28 {
  margin-left: 28px;
}
.ml131{
margin-left: 131px;
}

/* --------------------------------- */
.borderbox {
  box-sizing: border-box;
}
/* -------PAdding */
.p1o5 {
  padding: 1.5%;
  padding-top: 34px;
}

/* width */
.w30per {
  width: 40%;
}
.w40per {
  width: 40%;
}
.w250 {
  width: 250px;
}
.w50per {
  width: 50%;
}
.w60per {
  width: 60%;
}
.w40per {
  width: 40%;
}
.w25 {
  width: 25%;
}
.w10 {
  width: 10%;
}
.w15 {
  width: 15%;
}
.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}
.w70 {
  width: 70%;
}
.w80{
  width: 80%;
}
.w80per {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w104px {
  width: 104px;
}
.w200px {
  width: 200px;
}
.w494px {
  width: 494px;
}
.w208px{
  width: 208px;
}
/* -------------Gap */
.gap2 {
  gap: 2%;
}
.gap1 {
  gap: 1%;
}
.gap10 {
  gap: 10%;
}
.gap15 {
  gap: 15%;
}

.gap10px {
  gap: 10px;
}
.gap15px {
  gap: 15px;
}
